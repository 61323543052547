import axios from 'axios';


const adminUrl = process.env.VUE_APP_API_URL;
const adminUrlIframe = process.env.VUE_APP_WL_API_URL;

// const uploadUrl = process.env.VUE_APP_FILE_UPLOAD_URL;

export default {
  async getAllDesigners(data) {
    console.log('working');
    return axios.post(`${adminUrlIframe}/iframe/designerInFocus/searchAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getOneDesigner(params) {
    return axios.get(`${adminUrlIframe}/iframe/designerInFocus/getDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateDesignerData(params, data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrlIframe}/iframe/designerInFocus/updateDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createDesigner(data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrlIframe}/iframe/designerInFocus/createAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteDesigner(params) {
    console.log('working');
    // const data = {};
    return axios.delete(`${adminUrlIframe}/iframe/designerInFocus/deleteDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getAllSpotLights(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${adminUrl}/spotlightOn/searchAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getOneSpotLight(params) {
    console.log('working');
    return axios.get(`${adminUrl}/spotlightOn/getDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateSpotLight(params, data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrl}/spotlightOn/updateDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createSpotLight(data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrl}/spotlightOn/createAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteSpotLight(params) {
    console.log('working');
    // const data = {};
    return axios.delete(`${adminUrl}/spotlightOn/deleteDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getAllBanners(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${adminUrlIframe}/iframe/landingBanner/searchAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getOneBanner(params) {
    console.log('working');
    return axios.get(`${adminUrlIframe}/iframe/landingBanner/getDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateBannerData(params, data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrlIframe}/iframe/landingBanner/updateDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createBanner(data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrlIframe}/iframe/landingBanner/createAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteBanner(params) {
    console.log('working');
    // const data = {};
    return axios.delete(`${adminUrlIframe}/iframe/landingBanner/deleteDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getAllCuratedEdits(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${adminUrlIframe}/iframe/curatedEdits/searchAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getOneCuratedEdit(params) {
    console.log('working');
    return axios.get(`${adminUrlIframe}/iframe/curatedEdits/getDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateCuratedEditData(params, data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrlIframe}/iframe/curatedEdits/updateDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createCuratededit(data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrlIframe}/iframe/curatedEdits/createAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteCuratedEdit(params) {
    console.log('working');
    // const data = {};
    return axios.delete(`${adminUrlIframe}/iframe/curatedEdits/deleteDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getAllTopPicks(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${adminUrl}/topPicsOfWeek/searchAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getOneTopPick(params) {
    console.log('working');
    return axios.get(`${adminUrl}/topPicsOfWeek/getDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateTopPick(params, data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrl}/topPicsOfWeek/updateDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createTopPick(data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrl}/topPicsOfWeek/createAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteTopPick(params) {
    console.log('working');
    // const data = {};
    return axios.delete(`${adminUrl}/topPicsOfWeek/deleteDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getAllCategories(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${adminUrl}/category/searchAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getOneCategory(params) {
    console.log('working');
    return axios.get(`${adminUrl}/category/getDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateCategory(params, data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrl}/category/updateDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createCategory(data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrl}/category/createAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteCategory(params) {
    console.log('working');
    // const data = {};
    return axios.delete(`${adminUrl}/category/deleteDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },

  async getAllPosts(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${adminUrl}/post/searchAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getOnePost(params) {
    console.log('working');
    return axios.get(`${adminUrl}/post/getDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updatePost(params, data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrl}/post/updateDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createPost(data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrl}/post/createAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deletePost(params) {
    console.log('working');
    // const data = {};
    return axios.delete(`${adminUrl}/post/deleteDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getAllCustomBanners(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${adminUrlIframe}/iframe/customizationBackground/searchAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getOneCustomBanner(params) {
    console.log('working');
    return axios.get(`${adminUrlIframe}/iframe/customizationBackground/getDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateCustomBannerData(params, data) {
    console.log('working');
    // const data = {};
    return axios.post(
      `${adminUrlIframe}/iframe/customizationBackground/updateDataAdmin/${params}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      }
    );
  },
  async createCustomBanner(data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrlIframe}/iframe/customizationBackground/createAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteCustomBanner(params) {
    console.log('working');
    // const data = {};
    return axios.delete(
      `${adminUrlIframe}/iframe/customizationBackground/deleteDataAdmin/${params}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      }
    );
  },

  async getAllLatestArrivals(data) {
    // const data = {};
    console.log('working');
    return axios.post(`${adminUrlIframe}/iframe/latestArrival/searchAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getOneLatestArrival(params) {
    console.log('working');
    return axios.get(`${adminUrlIframe}/iframe/latestArrival/getDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateLatestArrival(params, data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrlIframe}/iframe/latestArrival/updateDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createLatestArrival(data) {
    console.log('working');
    // const data = {};
    return axios.post(`${adminUrlIframe}/iframe/latestArrival/createAdmin`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async deleteLatestArrival(params) {
    console.log('working');
    // const data = {};
    return axios.delete(`${adminUrlIframe}/iframe/latestArrival/deleteDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
};
